module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"the-bronson","short_name":"bronson","start_url":"/","background_color":"#133770","theme_color":"#133770","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5b7073b62bcf0516051275d5fa952a28"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
